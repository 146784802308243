<template>
    <!-- WARNING this file is generated edits made will be lost on next generation -->
    <div>
        <NavBar></NavBar>
        <div class="container-fluid">
            <b-row>
            <!-- Sidebar -->
            <TOCChapter chapter-id="Chap14Methods"></TOCChapter>
				<b-col ></b-col>
                <!-- Main Content -->
                <b-col role="main" md="6" >
					<ChapterHeading chapter-title="Chapter 14: Methods" image-name="methods.jpg" image-alt="Methods"></ChapterHeading>
                    <!-- Contents BEGIN -->
                    <div id="what-will-you-learn-in-this-chapter" class="anchor"></div>
<h1 data-number="1"><span class="header-section-number">1</span> What will you learn in this chapter? <a href="#what-will-you-learn-in-this-chapter"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>What is a method?</p></li>
<li><p>What is a receiver?</p></li>
<li><p>How to create a method.</p></li>
<li><p>How to call a method.</p></li>
<li><p>What is a pointer receiver, a value receiver?</p></li>
<li><p>What is a method set?</p></li>
<li><p>How to name your receivers.</p></li>
</ul>
<div id="technical-concepts-covered" class="anchor"></div>
<h2 data-number="1.1"><span class="header-section-number">1.1</span> Technical concepts covered <a href="#technical-concepts-covered"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ul>
<li><p>Receiver</p></li>
<li><p>Method</p></li>
<li><p>Parameter</p></li>
<li><p>Value receiver</p></li>
<li><p>Pointer type</p></li>
<li><p>Pointer receiver</p></li>
</ul>
<div id="what-is-a-method" class="anchor"></div>
<h1 data-number="2"><span class="header-section-number">2</span> What is a method? <a href="#what-is-a-method"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>A method is a <strong>function</strong> with a <strong>receiver</strong>.<a href="#fn1" class="footnote-ref" id="fnref1" role="doc-noteref"><sup>1</sup></a>.</p></li>
<li><p>The receiver of a method is a special <strong>parameter</strong>.</p></li>
<li><p>The receiver is not listed in the parameter list but before the method <strong>name</strong></p></li>
<li><p>A method can have only <strong>one receiver</strong>.</p></li>
<li><p>The receiver has a type <strong>T</strong> or <strong>T*</strong></p>
<ul>
<li><p>When the receiver has a type T, we say that it’s a “value receiver”</p></li>
<li><p>When it has a type T*, we say that it’s a “pointer receiver”</p></li>
</ul></li>
<li><p>We say that the <strong>base type</strong> is <strong>T</strong></p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/method_anatomy.png')" alt="Method anatomy"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Method anatomy</figcaption>
</figure>
<p>Example :</p>
<pre v-highlightjs><code class="go" v-pre >// methods/first-example/main.go
package main

import (
    &quot;os/user&quot;
    &quot;time&quot;

    &quot;github.com/Rhymond/go-money&quot;
)

type Item struct {
    ID string
}

type Cart struct {
    ID        string
    CreatedAt time.Time
    UpdatedAt time.Time
    lockedAt  time.Time
    user.User
    Items        []Item
    CurrencyCode string
    isLocked     bool
}

func (c *Cart) TotalPrice() (*money.Money, error) {
    // ...
    return nil, nil
}

func (c *Cart) Lock() error {
    // ...
    return nil
}</code></pre>
<p>We have defined the type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span>.</p>
<p>This type has two methods: <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> Those two methods are functions.</p>
<ul>
<li><p>They are bound to the type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span>.</p></li>
<li><p>The receiver for the method <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> is called <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> and is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*Cart</code></span></p></li>
<li><p>The receiver for the method <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> is called <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> and is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*Cart</code></span></p></li>
</ul>
<div id="methods-are-capabilities" class="anchor"></div>
<h1 data-number="3"><span class="header-section-number">3</span> Methods are capabilities <a href="#methods-are-capabilities"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>With methods, you can <strong>give additional capabilities</strong> to the cart Type. In the previous example, we add the capability for somebody that manipulate a Cart to :</p>
<ul>
<li><p>Lock the cart</p></li>
<li><p>Compute the total price</p></li>
</ul>
<div id="method-names" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="4"><span class="header-section-number">4</span> Method Names <a href="#method-names"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Method names should be <strong>unique</strong> inside a <strong>method set</strong>.</p></li>
<li><p>What is a method set?</p>
<ul>
<li><p>The method set of a type T is the group of all methods with a receiver T</p></li>
<li><p>The method set of a type *T is the group of all methods with a receiver T <strong>and *T</strong></p></li>
</ul></li>
</ul>
<p>It means that you CANNOT have two methods with the same name, even if the first one has a receiver type and the second one has a value type :</p>
<pre v-highlightjs><code class="go" v-pre >// Forbidden :


func (c *Cart) TotalPrice() (*money.Money, error) {
    //...
}

func (c Cart) TotalPrice() (*money.Money, error) {
    //...
}</code></pre>
<p>The previous snippet will not compile :</p>
<pre v-highlightjs><code class="go" v-pre ># maximilien-andile.com/methods/methodSet
./main.go:52:6: method redeclared: Cart.TotalPrice
    method(*Cart) func() (*money.Money, error)
    method(Cart) func() (*money.Money, error)

Compilation finished with exit code 2</code></pre>
<div id="how-to-call-methods" class="anchor"></div>
<h1 data-number="5"><span class="header-section-number">5</span> How to call methods <a href="#how-to-call-methods"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Methods are called with the “dot notation”. The receiver argument is passed to the method with a dot.</p>
<pre v-highlightjs><code class="go" v-pre >package main

import (
    &quot;call/cart&quot;
    &quot;log&quot;
)

func main() {
    // load the cart... into variable cart
    newCart := cart.Cart{}

    totalPrice, err := newCart.TotalPrice()
    if err != nil {
        log.Printf(&quot;impossible to compute price of the cart: %s&quot;, err)
        return
    }
    log.Println(&quot;Total Price&quot;, totalPrice.Display())

    err = newCart.Lock()
    if err != nil {
        log.Printf(&quot;impossible to lock the cart: %s&quot;, err)
        return
    }

}</code></pre>
<ul>
<li><p>In the previous example, the methods <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> are <strong>called</strong> (with the dot notation)</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">totalPrice, err := cart.TotalPrice()</code></span>, here we pass the variable <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> to the method <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span></p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">err = cart.Lock()</code></span>, here we pass the variable <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> to the method <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span></p></li>
<li><p>Those two methods are <strong>bound</strong> to the type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> from the current package (main)</p></li>
<li><p>We also call the method <span v-highlightjs><code class="go" v-pre style="display: inline">Display</code></span> bound to the type <span v-highlightjs><code class="go" v-pre style="display: inline">Money</code></span> from the package <span v-highlightjs><code class="go" v-pre style="display: inline">money</code></span></p></li>
<li><p>Note that the package cart belongs to the module “call”</p></li>
</ul>
<div id="should-i-use-a-pointer-receiver-or-a-value-receiver" class="anchor"></div>
<h1 data-number="6"><span class="header-section-number">6</span> Should I use a pointer receiver or a value receiver? <a href="#should-i-use-a-pointer-receiver-or-a-value-receiver"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>When you use a <strong>value receiver</strong> the data will be <strong>copied</strong> internally before the method is executed. The method will use a copy of the variable.</p>
<p>This has two consequences :</p>
<ol type="1">
<li><p>A method with a value receiver cannot modify the data passed to it</p></li>
<li><p>The internal copy process might impact your program’s performance (most of the time, it’s negligible, except for heavy type structs).</p></li>
</ol>
<p>With a pointer receiver, the data passed to it <strong>can be modified</strong> by the method.</p>
<div id="receiver-type-and-method-call" class="anchor"></div>
<h1 data-number="7"><span class="header-section-number">7</span> Receiver type and method call <a href="#receiver-type-and-method-call"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>The receiver is an additional function parameter.</p>
<p>Methods receivers are either pointer receivers or value receivers.</p>
<p>In this method, the receiver has the type <span v-highlightjs><code class="go" v-pre style="display: inline">*Cart</code></span> (a pointer to <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span>).</p>
<pre v-highlightjs><code class="go" v-pre >func (c *Cart) TotalPrice() (*money.Money, error) {
    // ...
    return total, nil
}</code></pre>
<p>When we call this method, we use the following notation :</p>
<pre v-highlightjs><code class="go" v-pre >newCart := cart.Cart{}
totalPrice, err := newCart.TotalPrice()
//...</code></pre>
<p>Did you notice something weird?</p>
<ul>
<li><p>We have learned that a function parameter has a type. This type should be respected (you cannot give a function an <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> if it expects a string).</p></li>
<li><p>The type of <span v-highlightjs><code class="go" v-pre style="display: inline">newCart</code></span> is <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> (from <span v-highlightjs><code class="go" v-pre style="display: inline">cart</code></span> package)</p></li>
<li><p>The type of the receiver is <span v-highlightjs><code class="go" v-pre style="display: inline">*Cart</code></span>.</p></li>
<li><p>Types are not the same!</p></li>
</ul>
<p>Should it trigger an error, no? It does not. Why?</p>
<ul>
<li>Go will convert the variable <span v-highlightjs><code class="go" v-pre style="display: inline">newCart</code></span> automatically to a pointer.</li>
</ul>
<div id="rules" class="anchor"></div>
<h4 data-number="7.0.0.1"><span class="header-section-number">7.0.0.1</span> Rules <a href="#rules"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>Methods with pointer receivers can take a pointer OR a value as receiver</p></li>
<li><p>Methods with value receivers can take a pointer OR a value as receiver</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/indirection_methods.png')" alt="Impact of Receivers types on method calling"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Impact of Receivers types on method calling</figcaption>
</figure>
<div id="methods-visibility" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="8"><span class="header-section-number">8</span> Methods visibility <a href="#methods-visibility"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>Methods like functions have a visibility.</p>
<p>When the <strong>first letter</strong> of the method name is <strong>capitalized</strong>, the method is <strong>exported</strong>.</p>
<p>In the previous example, we put all our code into the main package; consequently, method visibility did not matter much. However, when you create a package, you must consider methods’ visibility.</p>
<ul>
<li><p>An exported method is callable outside the package.</p></li>
<li><p>A non exported method is NOT callable outside the package.</p></li>
</ul>
<div id="example-project" class="anchor"></div>
<h2 data-number="8.1"><span class="header-section-number">8.1</span> Example project <a href="#example-project"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<p>Let’s consider a new organization (see figure).</p>
<ul>
<li><p>We have the go.mod, go.sum, and main at the root.go (the application)</p></li>
<li><p>We have three directories; each directory contains source files for a package.</p></li>
<li><p>We have three packages :</p>
<ul>
<li><p>cart</p></li>
<li><p>product</p></li>
<li><p>user</p></li>
</ul></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/methods_visibility_package_tree.png')" alt="Project tree view"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Project tree view</figcaption>
</figure>
<p>Here is the content of the cart package :</p>
<pre v-highlightjs><code class="go" v-pre >// methods/example-project/cart/cart.go
package cart

import (
    &quot;methods/example-project/product&quot;
    &quot;os/user&quot;
    &quot;time&quot;

    &quot;github.com/Rhymond/go-money&quot;
)

type Cart struct {
    ID        string
    CreatedAt time.Time
    UpdatedAt time.Time
    lockedAt  time.Time
    user.User
    Items        []Item
    CurrencyCode string
    isLocked     bool
}

type Item struct {
    product.Product
    Quantity uint8
}

func (c *Cart) TotalPrice() (*money.Money, error) {
    //...
    return nil, nil
}

func (c *Cart) Lock() error {
    //...
    return nil
}

func (c *Cart) delete() error {
    // to implement
    return nil
}</code></pre>
<p>The product package :</p>
<pre v-highlightjs><code class="go" v-pre >// methods/example-project/product/product.go
package product

import &quot;github.com/Rhymond/go-money&quot;

type Product struct {
    ID    string
    Name  string
    Price *money.Money
}</code></pre>
<p>The user package :</p>
<pre v-highlightjs><code class="go" v-pre >// methods/example-project/user/user.go
package user

type User struct {
    ID        string
    Firstname string
    Lastname  string
}</code></pre>
<p>And the main package (program starting point) :</p>
<pre v-highlightjs><code class="go" v-pre >// methods/example-project/main.go
package main

import (
    &quot;log&quot;
    &quot;methods/example-project/cart&quot;
)

func main() {
    newCart := cart.Cart{}

    totalPrice, err := newCart.TotalPrice()
    if err != nil {
        log.Printf(&quot;impossible to compute price of the cart: %s&quot;, err)
        return
    }
    log.Println(&quot;Total Price&quot;, totalPrice.Display())

    err = newCart.Lock()
    if err != nil {
        log.Printf(&quot;impossible to lock the cart: %s&quot;, err)
        return
    }

}</code></pre>
<div id="notes" class="anchor"></div>
<h4 data-number="8.1.0.1"><span class="header-section-number">8.1.0.1</span> Notes <a href="#notes"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<ul>
<li><p>The methods <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> (bound to <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> ) are exported</p></li>
<li><p>However the method <span v-highlightjs><code class="go" v-pre style="display: inline">delete</code></span> bound to <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> is not exported</p></li>
<li><p>It means that <span v-highlightjs><code class="go" v-pre style="display: inline">delete</code></span> <strong>cannot</strong> be called from other packages (main, user, product)</p></li>
<li><p>But <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> can be called in other packages.</p></li>
</ul>
<div id="receiver-name-conventions" class="anchor"></div>
<h1 data-number="9"><span class="header-section-number">9</span> Receiver name conventions <a href="#receiver-name-conventions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<p>We can choose receiver names freely. However, two practices are adopted among the community :</p>
<ul>
<li><p>The receiver name is usually a <strong>single letter</strong></p></li>
<li><p>Generally the <strong>first letter of the base type</strong></p>
<ul>
<li><p>Base type : <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span>, receiver name <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span></p></li>
<li><p>Base type : <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span>, receiver name <span v-highlightjs><code class="go" v-pre style="display: inline">u</code></span></p></li>
<li><p>...</p></li>
</ul></li>
<li><p>When you chose a receiver name <strong>stick with it</strong> in all your methods.</p>
<ul>
<li>Use <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> for all methods of the base type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span></li>
</ul></li>
</ul>
<div id="application-exercise" class="anchor"></div>
<h1 data-number="10"><span class="header-section-number">10</span> Application exercise <a href="#application-exercise"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="specification" class="anchor"></div>
<h2 data-number="10.1"><span class="header-section-number">10.1</span> Specification <a href="#specification"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>Create a new module on your computer</p></li>
<li><p>Copy and paste the code provided in the previous section.</p></li>
<li><p>Implement the two methods : <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> and <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span></p>
<ol type="1">
<li><p>The method <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> computes the <strong>total price</strong> of the cart and return it. You should use the module <span v-highlightjs><code class="go" v-pre style="display: inline">github.com/Rhymond/go-money</code></span></p>
<ol type="1">
<li>The total price of the cart is for each article, the sum of the quantity * the unit price.</li>
</ol></li>
<li><p>The method <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> will... lock the cart to avoid modifications after confirmation</p>
<ol type="1">
<li><p>The method should update the field <span v-highlightjs><code class="go" v-pre style="display: inline">isLocked</code></span> to true</p></li>
<li><p>It should also update the field <span v-highlightjs><code class="go" v-pre style="display: inline">lockedAt</code></span> to the current time</p></li>
<li><p>If the cart is already locked, it should return an error</p></li>
</ol></li>
</ol></li>
</ol>
<p>You will need to take a look at the <strong>documentation</strong> of the <span v-highlightjs><code class="go" v-pre style="display: inline">github.com/Rhymond/go-money</code></span> module.</p>
<ul>
<li><p>The reflex is to go to https://pkg.go.dev/</p></li>
<li><p>Type “go-money” in the search bar and click on the module:</p>
<ul>
<li>https://pkg.go.dev/github.com/Rhymond/go-money</li>
</ul></li>
<li><p>Click on “<strong>Expand</strong>” you will see the README.md file of the repository that gives you an example usage</p></li>
</ul>
<figure>
<b-img :src="require('@/assets/images/go-money-doc.png')" alt="Impact of Receivers types on method calling"  fluid thumbnail class="img-book"></b-img><figcaption aria-hidden="true">Impact of Receivers types on method calling</figcaption>
</figure>
<div id="solution-totalprice" class="anchor"></div>
<h2 data-number="10.2"><span class="header-section-number">10.2</span> Solution: <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span> <a href="#solution-totalprice"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="unit-test" class="anchor"></div>
<h3 data-number="10.2.1"><span class="header-section-number">10.2.1</span> Unit test <a href="#unit-test"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<p>Let’s begin by writing the unit test. Note that we will cover more in detail unit tests in a dedicated chapter. For the moment if you are not familiar with this, just assum that this is a tool for testing that a method behave as expected.</p>
<pre v-highlightjs><code class="go" v-pre >package cart

// imports...

func TestTotalPrice(t *testing.T) {
    items := []Item{
        {
            Product: product.Product{
                ID:    &quot;p-1254&quot;,
                Name:  &quot;Product test&quot;,
                Price: money.New(1000, &quot;EUR&quot;),
            },
            Quantity: 2,
        },
        {
            Product: product.Product{
                ID:    &quot;p-1255&quot;,
                Name:  &quot;Product test 2&quot;,
                Price: money.New(2000, &quot;EUR&quot;),
            },
            Quantity: 1,
        },
    }
    c := Cart{
        ID:           &quot;1254&quot;,
        CreatedAt:    time.Now(),
        UpdatedAt:    time.Now(),
        User:         user.User{},
        Items:        items,
        CurrencyCode: &quot;EUR&quot;,
    }
    actual, err := c.TotalPrice()
    assert.NoError(t, err)
    assert.Equal(t, money.New(4000, &quot;EUR&quot;), actual)
}</code></pre>
<ul>
<li><p>The unit test function is named <span v-highlightjs><code class="go" v-pre style="display: inline">TotalPrice</code></span>,</p></li>
<li><p>We begin by creating a slice of 2 fake <span v-highlightjs><code class="go" v-pre style="display: inline">Item</code></span> elements : <span v-highlightjs><code class="go" v-pre style="display: inline">items</code></span></p>
<ul>
<li><p>The <span v-highlightjs><code class="go" v-pre style="display: inline">ID</code></span> and the <span v-highlightjs><code class="go" v-pre style="display: inline">Name</code></span> fields are filled with test data</p></li>
<li><p>To create a price, simply call <span v-highlightjs><code class="go" v-pre style="display: inline">money.New(1000, "EUR")</code></span></p></li>
<li><p>The two items have different prices: 10.00 EUR for the first and 20.00 for the second (2000 divided by 100 = 20)</p></li>
</ul></li>
<li><p>Then a new variable <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> of type <span v-highlightjs><code class="go" v-pre style="display: inline">Cart</code></span> is created.</p></li>
<li><p>The field <span v-highlightjs><code class="go" v-pre style="display: inline">Items</code></span> is set to the value <span v-highlightjs><code class="go" v-pre style="display: inline">items</code></span></p></li>
<li><p>The field <span v-highlightjs><code class="go" v-pre style="display: inline">CurrencyCode</code></span> is set to “EUR” (the euro currency code)</p></li>
<li><p>The method is then called on <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span></p></li>
<li><p>Then we check that there is no error</p>
<ul>
<li>With <span v-highlightjs><code class="go" v-pre style="display: inline">assert.NoError(t, err)</code></span></li>
</ul></li>
<li><dl>
<dt>And we verify that the actual value of the first result is equal to</dt>
<dd><span v-highlightjs><code class="go" v-pre style="display: inline">money.New(4000, "EUR")</code></span> (40 Euros)
</dd>
</dl>
<ul>
<li>2 * 10 EUR + 1 * 20 EUR= 40 EUR</li>
</ul></li>
</ul>
<div id="function-implementation" class="anchor"></div>
<h3 data-number="10.2.2"><span class="header-section-number">10.2.2</span> Function implementation <a href="#function-implementation"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// methods/application/cart/cart.go 
//...

func (c *Cart) TotalPrice() (*money.Money, error) {
    total := money.New(0, c.CurrencyCode)
    var err error
    for _, v := range c.Items {
        itemSubtotal := v.Product.Price.Multiply(int64(v.Quantity))
        total, err = total.Add(itemSubtotal)
        if err != nil {
            return nil, err
        }
    }
    return total, nil
}</code></pre>
<ul>
<li><p>We create a variable <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span> initialized with 0 EUR (with the function <span v-highlightjs><code class="go" v-pre style="display: inline">money.New</code></span> that will create a variable of type <span v-highlightjs><code class="go" v-pre style="display: inline">*money.Money</code></span></p>
<ul>
<li>This will be the grand total</li>
</ul></li>
<li><p>We create a variable err of type error</p></li>
<li><p>Then for each item in the basket</p>
<ul>
<li><p>We compute the total price for the item : <span v-highlightjs><code class="go" v-pre style="display: inline">itemSubtotal</code></span>.</p>
<ul>
<li><p>We call the method <span v-highlightjs><code class="go" v-pre style="display: inline">Multiply</code></span> from the type <span v-highlightjs><code class="go" v-pre style="display: inline">*money.Money</code></span></p></li>
<li><p>The receiver parameter is in that case <span v-highlightjs><code class="go" v-pre style="display: inline">v.Product.Price</code></span> (which is the price of the current product)</p></li>
<li><p>The method takes the quantity as parameter (we convert it from <span v-highlightjs><code class="go" v-pre style="display: inline">uint8</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">int64</code></span>)</p></li>
</ul></li>
<li><p>Then this subtotal is added to the grand total</p>
<ul>
<li><p>We call the method <span v-highlightjs><code class="go" v-pre style="display: inline">Add</code></span> (defined on the type <span v-highlightjs><code class="go" v-pre style="display: inline">*money.Money</code></span>)</p></li>
<li><p>The receiver parameter is <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span></p></li>
<li><p>The result is then reassigned to the variable <span v-highlightjs><code class="go" v-pre style="display: inline">total</code></span></p></li>
</ul></li>
</ul></li>
</ul>
<div id="solution-lock" class="anchor"></div>
<h2 data-number="10.3"><span class="header-section-number">10.3</span> Solution: <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> <a href="#solution-lock"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<div id="unit-tests" class="anchor"></div>
<h3 data-number="10.3.1"><span class="header-section-number">10.3.1</span> Unit tests <a href="#unit-tests"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// methods/application/cart/cart_test.go 
//...

func TestLock(t *testing.T) {
    c := Cart{
        ID: &quot;1254&quot;,
    }
    err := c.Lock()
    assert.NoError(t, err)
    assert.True(t, c.isLocked)
    assert.True(t, c.lockedAt.Unix() &gt; 0)
}

func TestLockAlreadyLocked(t *testing.T) {
    c := Cart{
        ID:       &quot;1254&quot;,
        isLocked: true,
    }
    err := c.Lock()
    assert.Error(t, err)
}</code></pre>
<ul>
<li><p>First we test that when a cart is locked :</p>
<ul>
<li><p>the field <span v-highlightjs><code class="go" v-pre style="display: inline">isLocked</code></span> is equal to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span></p></li>
<li><p>the field <span v-highlightjs><code class="go" v-pre style="display: inline">lockedAt</code></span> is set with a time corresponding to a UNIX epoch greater than 0</p>
<ul>
<li><p>To do that, we get the UNIX epoch with the method <span v-highlightjs><code class="go" v-pre style="display: inline">Unix</code></span> defined on the type <span v-highlightjs><code class="go" v-pre style="display: inline">time.Time</code></span></p></li>
<li><p>This is not optimal</p></li>
<li><p>A better test would have checked that the time is correctly set.</p></li>
</ul></li>
</ul></li>
</ul>
<div id="bonus-question" class="anchor"></div>
<h4 data-number="10.3.1.1"><span class="header-section-number">10.3.1.1</span> Bonus question <a href="#bonus-question"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h4>
<p>How to check that the time is correctly set? Maybe we could modify the method signature by adding a lock time as parameter. That way, we can check that <span v-highlightjs><code class="go" v-pre style="display: inline">lockedAt</code></span> is correctly set.</p>
<ul>
<li><p>The second function will check that the function behaves as expected when the cart is already locked</p>
<ul>
<li><p>To do so, we set <span v-highlightjs><code class="go" v-pre style="display: inline">isLocked</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span></p></li>
<li><p>Then we call <span v-highlightjs><code class="go" v-pre style="display: inline">Lock</code></span> to check that an error occurred.</p></li>
</ul></li>
</ul>
<div id="function-implementation-1" class="anchor"></div>
<h3 data-number="10.3.2"><span class="header-section-number">10.3.2</span> Function implementation <a href="#function-implementation-1"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h3>
<pre v-highlightjs><code class="go" v-pre >// methods/application/cart/cart.go 
//...

func (c *Cart) Lock() error {
    if c.isLocked {
        return errors.New(&quot;cart is already locked&quot;)
    }
    c.isLocked = true
    c.lockedAt = time.Now()
    return nil
}</code></pre>
<ul>
<li><p>First, we check that the cart is not already locked.</p>
<ul>
<li>If it’s locked, we return a new error (created with <span v-highlightjs><code class="go" v-pre style="display: inline">errors.New</code></span> )</li>
</ul></li>
<li><p>Then we set the value of is <span v-highlightjs><code class="go" v-pre style="display: inline">isLocked</code></span> to <span v-highlightjs><code class="go" v-pre style="display: inline">true</code></span></p></li>
<li><p>And the value of <span v-highlightjs><code class="go" v-pre style="display: inline">lockedAt</code></span> to the current time (<span v-highlightjs><code class="go" v-pre style="display: inline">time.Now()</code></span>) .</p></li>
</ul>
<div id="test-yourself" class="anchor"></div>
<h1 data-number="11"><span class="header-section-number">11</span> Test yourself <a href="#test-yourself"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<div id="questions" class="anchor"></div>
<h2 data-number="11.1"><span class="header-section-number">11.1</span> Questions <a href="#questions"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is a method receiver?</p></li>
<li><p>Give an example of a method with a value receiver.</p></li>
<li><p>Give an example of a method with a pointer receiver.</p></li>
<li><p>Fill in the blanks. A method with a _______ receiver can modify their receiver.</p></li>
<li><p>True or False? Given a type <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span>, we can have a method named <span v-highlightjs><code class="go" v-pre style="display: inline">Logout</code></span> with a value receiver <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span> and a method named <span v-highlightjs><code class="go" v-pre style="display: inline">Logout</code></span> with a pointer receiver <span v-highlightjs><code class="go" v-pre style="display: inline">*User</code></span>.</p></li>
<li><p>How to control method visibility?</p></li>
</ol>
<div id="answers" class="anchor"></div>
<h2 data-number="11.2"><span class="header-section-number">11.2</span> Answers <a href="#answers"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h2>
<ol type="1">
<li><p>What is a method receiver?</p>
<ol type="1">
<li><p>A method receiver is an additional parameter</p></li>
<li><p>It is specified in a special parameter section that precedes the method name.</p></li>
</ol>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func (c *Cart) Lock() error</code></pre>
<ol type="1">
<li>Here the receiver is named <span v-highlightjs><code class="go" v-pre style="display: inline">c</code></span> and is of type <span v-highlightjs><code class="go" v-pre style="display: inline">*Cart</code></span></li>
</ol></li>
<li><p>Give an example of a method with a value receiver.</p>
<pre v-highlightjs><code class="go" v-pre >func (t T) MethodExampleName() error</code></pre></li>
<li><p>Give an example of a method with a pointer receiver.</p>
<pre v-highlightjs><code class="go" v-pre >func (t *T) MethodExampleName2() error</code></pre></li>
<li><p>Fill the blanks. A method with a _______ receiver can modify their receiver.</p>
<ol type="1">
<li>A method with a pointer receiver can modify their receiver.</li>
</ol></li>
<li><p>True or False ? Given a type <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span>, we can have a method named <span v-highlightjs><code class="go" v-pre style="display: inline">Logout</code></span> with a value receiver <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span> and a method named <span v-highlightjs><code class="go" v-pre style="display: inline">Logout</code></span> with a pointer receiver <span v-highlightjs><code class="go" v-pre style="display: inline">*User</code></span>.</p>
<ol type="1">
<li><p>False</p></li>
<li><p>The type <span v-highlightjs><code class="go" v-pre style="display: inline">*User</code></span> has a method set composed of all the methods with a receiver of type <span v-highlightjs><code class="go" v-pre style="display: inline">*User</code></span> and methods with a receiver of type <span v-highlightjs><code class="go" v-pre style="display: inline">User</code></span></p></li>
<li><p>The name of methods inside a method set should be unique.</p></li>
<li><p>In other words you cannot have a method with the same name, <strong>even if</strong> the receiver is a pointer receiver or a value receiver.</p></li>
</ol></li>
<li><p>How to control method visibility?</p>
<ol type="1">
<li><p>To make it <strong>visible</strong> outside the package where it’s defined, name the method with a first letter <strong>capitalized</strong></p></li>
<li><p>To make it <strong>invisible</strong> outside the package where it’s defined, name the method with a first letter <strong>NOT capitalized</strong></p></li>
</ol></li>
</ol>
<div id="key-takeaways" class="anchor"></div>
<BuyCopyInvite></BuyCopyInvite>
<h1 data-number="12"><span class="header-section-number">12</span> Key Takeaways <a href="#key-takeaways"><b-icon-link45deg font-scale="0.7" class="heading-link-icon"></b-icon-link45deg></a></h1>
<ul>
<li><p>Methods are <strong>functions</strong> that is attached to a type</p></li>
<li><p>Each method has a receiver</p></li>
<li><p>The receiver is an additional parameter specified before the name of the function</p></li>
</ul>
<!-- -->
<pre v-highlightjs><code class="go" v-pre >func (t T) MethodExampleName() error</code></pre>
<ul>
<li><p>The receiver can be a pointer to a type <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span> (denoted <span v-highlightjs><code class="go" v-pre style="display: inline">*T</code></span>)</p>
<ul>
<li>We say that the method has a <strong>pointer receiver</strong> in that case</li>
</ul></li>
<li><p>The receiver can have a type <span v-highlightjs><code class="go" v-pre style="display: inline">T</code></span></p>
<ul>
<li>We say that the method has a <strong>value receiver</strong> in that case</li>
</ul></li>
<li><p>When your method has a pointer receiver, you allow the method to modify the receiver’s value.</p>
<pre v-highlightjs><code class="go" v-pre >func (c *Cart) Lock() error</code></pre>
<ul>
<li>This method can update the value of the cart.</li>
</ul></li>
<li><p>Generally, the receiver has a <strong>one letter name</strong>, that is generally the <strong>first letter of the receiver type</strong></p>
<ul>
<li>Ex :</li>
</ul>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">func (c *Cart) Lock() error</code></span> (package <u>time</u>, standard library)</p>
<p><span v-highlightjs><code class="go" v-pre style="display: inline">func (m *Money) Multiply(mul int64) *Money</code></span> (package money, module <u>github.com/Rhymond/go-money</u>)</p></li>
<li><p>The first letter of the method name control its visibility</p>
<ul>
<li><p>First letter capitalized: Visible</p></li>
<li><p>First letter not capitalized: Impossible to call it from outside the package.</p></li>
</ul></li>
<li><p>Method names should be <strong>unique</strong> in a method set</p></li>
</ul>
<section class="footnotes" role="doc-endnotes">
<hr />
<ol>
<li id="fn1" role="doc-endnote"><p>https://golang.org/ref/spec#Method_declarations<a href="#fnref1" class="footnote-back" role="doc-backlink">↩︎</a></p></li>
</ol>
</section>

                    <!-- END CONTENT -->
                    <!-- Bibliography -->
                    <h1>Bibliography</h1>
                    <ChapterBibliography chapter-id="Chap14Methods"></ChapterBibliography>
					<!-- Next / Previous -->
					<b-row class="ml-1 mr-1 ">
						
							<b-col class="text-center border mr-1 p-2" >
								<router-link :to="{name:'Chap13Types'}">
									<p><u><small>Previous</small></u></p>
									<p><small>Types</small></p>
								</router-link>
							</b-col>
						
						
							<b-col class="text-center border p-1 ">
								<router-link :to="{name:'Chap15PointerType'}">
									<p><u><small>Next</small></u></p>
									<p><small>Pointer type</small></p>
								</router-link>
							</b-col>
						
					</b-row>
					<b-row class="mt-1 ml-1 mr-1">
						<b-col class="text-center border p-1 ">
							<b-link :to="{name:'Home'}" >Table of contents</b-link>
						</b-col>
					</b-row>
          			<FeedbackInvite></FeedbackInvite>
					<NewsletterInput></NewsletterInput>
					<Footer></Footer>
                </b-col>
				<b-col ></b-col>
            </b-row>
        </div>
    </div>
</template>

<script>
import TOCChapter from "@/components/toc/TocChapter";
import ChapterBibliography from "@/components/ChapterBibliography";
import NavBar from "@/components/NavBar";
import { BIconLink45deg } from 'bootstrap-vue'
import Footer from "@/components/Footer";
import ChapterHeading from "@/components/ChapterHeading";
import BuyCopyInvite from "@/components/BuyCopyInvite";
import NewsletterInput from "@/components/NewsletterInput";
import FeedbackInvite from "@/components/FeedbackInvite";

const title = "Methods - Practical Go Lessons"
const description = "With methods, you can give additional capabilities to the a type. Method names should be unique inside a method set. How to create methods in Go ?"

export default {
  name: "Chap14Methods",
  components: {FeedbackInvite,BuyCopyInvite,NewsletterInput,ChapterHeading, ChapterBibliography,TOCChapter,NavBar,BIconLink45deg, Footer},

  created() {
    window.scrollTo(0,0);
  },data () {return {publicPath: process.env.BASE_URL}},
  metaInfo: {
      title: title,
      htmlAttrs: {
        lang: 'en',
      },
      meta: [
        { charset: 'utf-8' },
        { name: 'description', content: description },
        { name: 'robots', content: "index, follow" },
        { property: 'og:locale', content: process.env.VUE_APP_SITE_LOCALE_META },
        { property: 'og:type', content: "website" },
        { property: 'og:title', content: title},
        { property: 'og:description', content: description },
        { property: 'og:url', content: window.location.href },
        { property: 'og:site_name', content: 'Practical Go Lessons' },
        { property: 'twitter:card', content: "summary_large_image" },
        { property: 'twitter:creator', content: process.env.VUE_APP_TWITTER_USERNAME }
      ],
      link : [
        { rel : "canonical", href : window.location.href}
      ]

    }
}
</script>

<style scoped>

</style>
